








import { defineComponent } from '@vue/composition-api';

import { useNetworkStore } from '../stores/Network';
import WorldIcon from './icons/WorldIcon.vue';
import WorldCheckIcon from './icons/WorldCheckIcon.vue';
import WorldAlertIcon from './icons/WorldAlertIcon.vue';

export default defineComponent({
    setup() {
        const { consensus } = useNetworkStore();

        return {
            consensus,
        };
    },
    components: {
        WorldIcon,
        WorldCheckIcon,
        WorldAlertIcon,
    },
});
