














































import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { HexagonIcon } from '@nimiq/vue-components';

export enum LoadingListType {
    TRANSACTION,
    VALIDATOR,
}

export default defineComponent({
    props: {
        delay: Number, // in milliseconds
        length: {
            type: Number,
            default: 1,
        },
        type: {
            type: Number as () => LoadingListType,
            default: LoadingListType.TRANSACTION,
        },
    },
    setup(props) {
        const list = computed(() => Array.from({ length: props.length }));
        const animating = ref(false);

        onMounted(() => {
            setTimeout(() => {
                animating.value = true;
            });
        });

        onUnmounted(() => {
            animating.value = false;
        });

        return {
            LoadingListType,
            list,
            animating,
        };
    },
    components: {
        HexagonIcon,
    },
});
