







import { defineComponent } from '@vue/composition-api';
import Amount from '../Amount.vue';
import { useStakingStore } from '../../stores/Staking';
import { CryptoCurrency } from '../../lib/Constants';
import TwoLeafStakingIcon from '../icons/Staking/TwoLeafStakingIcon.vue';

export default defineComponent({
    setup() {
        const { totalAccountStake } = useStakingStore();

        return {
            totalAccountStake,
            CryptoCurrency,
        };
    },
    components: {
        Amount,
        TwoLeafStakingIcon,
    },
});
